<template>
  <div class="jjk-title">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "",
  components: {},
  setup() {
    return {};
  }
});
</script>

<style lang="scss" scoped>
.jjk-title {
  font-size: 1.5em;
  padding: 1em;
  font-weight: bold;
  color: rgba(var(--ion-color-primary-rgb), 1);
}
</style>
