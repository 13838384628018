import { STARLING_ACCESS_TOKEN } from "@/.env";
import axios, { AxiosRequestConfig } from "axios";

const apiUrl = "https://api.starlingbank.com";

const accessToken = STARLING_ACCESS_TOKEN;

const options = {
  crossDomain: true,
  headers: {
    authorization: `Bearer ${accessToken}`
  }
} as AxiosRequestConfig;

export const getAccount = async () => {
  const request = await axios.get(`${apiUrl}/api/v2/accounts`, options);
  console.log(request);
  return request;
};
