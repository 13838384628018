
import { useAccessor } from "..";
import { defineComponent } from "vue";
import { IonCard } from "@ionic/vue";
import JJKTitle from "@/components/JJKTitle.vue";
import { getAccount } from "../services/starling";

export default defineComponent({
  name: "ViewBudget",
  components: { IonCard, JJKTitle },
  setup() {
    const accessor = useAccessor();

    getAccount();
    console.log(accessor.budgets[0]);

    return {};
  }
});
