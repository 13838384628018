<template>
  <div id="view-budget">
    <JJKTitle>Budget for 31 days from 9 Feb - 8 Mar</JJKTitle>
    <ion-card>Budgets</ion-card>
  </div>
</template>

<script lang="ts">
import { useAccessor } from "..";
import { defineComponent } from "vue";
import { IonCard } from "@ionic/vue";
import JJKTitle from "@/components/JJKTitle.vue";
import { getAccount } from "../services/starling";

export default defineComponent({
  name: "ViewBudget",
  components: { IonCard, JJKTitle },
  setup() {
    const accessor = useAccessor();

    getAccount();
    console.log(accessor.budgets[0]);

    return {};
  }
});
</script>

<style lang="scss" scoped></style>
